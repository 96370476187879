import React from 'react';
import { Typography } from '@heycater/design-system';
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

function Accordion({ summary, details }) {
  return (
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        {typeof summary === 'string' ? (
          <Typography
            py={1}
            variant="subtitle1"
            fontWeight={600}
            fontSize={16}
            component="h3"
          >
            {summary}
          </Typography>
        ) : (
          summary
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {typeof details === 'string' ? (
          <Typography fontSize={16} pb={2} variant="subtitle2">
            {details}
          </Typography>
        ) : (
          <>{details}</>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: ${({ theme }) => theme.spacing(0, 1.5)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(0, 3)};
  }

  min-height: auto;
  &.Mui-expanded {
    min-height: auto;
    color: #4e7a0a;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;
  }
`;

const StyledAccordion = styled(MUIAccordion)`
  border-radius: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(1.5)}px;

  background-color: #f0f0f0;
  box-shadow: none;

  &.Mui-expanded {
    background-color: #f8f8f8;
  }

  :before {
    display: none;
  }

  &.MuiExpansionPanel-root {
    border: none;
    border-radius: 8px 8px 0 0;
  }

  &.MuiExpansionPanel-root:before {
    display: none;
    height: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
  color: ${(props) => props.theme.palette.grey[700]};
  padding: ${({ theme }) => theme.spacing(1, 3, 0, 1.5)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(0, 8, 0, 3)};
  }

  & > p {
    margin-top: 0;
  }
`;

export default Accordion;
