import React, { ReactNode } from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Block, Inline, INLINES } from '@contentful/rich-text-types';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import { useRouter } from 'next/router';

import { IFaqItem } from 'lib/types/generated/contentful';
import Accordion from 'shared/components/Accordion';
import { buildFunnelUrl } from 'static_pages/marketing_pages/helpers';

type Props = BoxProps & {
  faqs: IFaqItem[];
  headline: string;
};

const Faqs = (props: Props) => {
  const router = useRouter();

  const renderOptions: Options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node: Inline | Block, children: ReactNode) => {
        let href = node.data.uri;

        const isLeadingToQF =
          typeof href === 'string' &&
          (href.endsWith('/start') || href.endsWith('/start/'));

        if (isLeadingToQF) {
          href = buildFunnelUrl({ router });
        }

        return <a href={href}>{children}</a>;
      },
    },
  };

  return (
    <Box as="section" mb={1.5}>
      <Box textAlign="center">
        <Typography variant="h3" mb={6} component="h2">
          {props.headline}
        </Typography>
      </Box>
      <Box>
        {props.faqs.map((faq) => (
          <Accordion
            key={faq.sys.id}
            summary={faq.fields.question}
            details={documentToReactComponents(
              faq.fields.answer,
              renderOptions
            )}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Faqs;
